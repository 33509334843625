import { ISettingsForm } from "../../models/setting-form.interface";
import { combineLatest } from "rxjs";
import { IUser } from "../../models/user.interface";
import { IStreamData, IStreamKey } from "../stream/stream.service";
import { SettingsService, settingsService } from "./settings.service";
import { SettingsQuery } from "./settings.query";
import { settingsQuery, settingsStore, SettingsStore } from "./settings.store";

export interface ISettingViewModel {
  user: IUser;
  stream: IStreamData;
  settings: ISettingsForm;
}
export class SettingFacade {
  settings$ = this.query.settings$;
  isDiscord$ = this.query.isDiscord$;
  playbackUrl$ = this.query.playbackUrl$;
  isTipEnabled$ = this.query.isTipEnable$;
  isAnalyticsEnabled$ = this.query.isAnalyticsEnabled$;
  adDetails$ = this.query.adDetails$;
  isSubscriptionEnabled$ = this.query.isSubscriptionEnabled$;
  googleAnalyticsId$ = this.query.googleAnalyticsId$;
  constructor(
    private store: SettingsStore,
    private query: SettingsQuery,
    private service: SettingsService
  ) {}

  getAllData(userId: string): void {
    combineLatest([
      this.service.getStreamData(),
      this.service.getUserSettings(userId),
    ]).subscribe(this.onSuccessSettingsData, this.onErrorSettingsData);
  }

  getPublicData(): void {
    combineLatest([
      this.service.getPublicStreamData(),
      this.service.getPublicSettings(),
    ]).subscribe(this.onSuccessSettingsData, this.onErrorSettingsData);
  }

  updateSettings(updatedSettings: ISettingsForm, userId: string) {
    const onSuccess = (settings: ISettingsForm) => {
      this.store.add(updatedSettings);
    };
    const onError = (error: any) => {
      console.log("error while updatings", error);
    };
    if (updatedSettings.isAdminSettingCreated) {
      this.service
        .updateSettings(updatedSettings, userId)
        .subscribe(onSuccess, onError);
    } else {
      this.service.addSettings(
        {
          ...updatedSettings,
          isAdminSettingCreated: true,
        },
        userId
      );
    }
  }

  resetStreamKey() {
    const onSuccess = (data: IStreamKey) => {
      this.store.updateValue(data.value);
    };
    const onError = (error: any) => {
      console.log("error", error);
    };
    this.service.resetStreamData().subscribe(onSuccess, onError);
  }

  private onSuccessSettingsData = ([stream, settings]: [
    IStreamData,
    ISettingsForm
  ]) => {
    const { stream_secret_key, stream_url, playbackUrl } = stream;
    const updatedSettingsValues: ISettingsForm = {
      ...settings,
      stream_url,
      stream_secret_key,
      playbackUrl,
    };
    this.store.add(updatedSettingsValues);
  };

  private onErrorSettingsData = () => {
    console.error("error getting setting data");
  };
}

export const settingFacade = new SettingFacade(
  settingsStore,
  settingsQuery,
  settingsService
);
