import { Query, toBoolean } from "@datorama/akita";
import { IUser } from "../../../models/user.interface";
import { UserStore } from "./user.store";
import { Observable } from "rxjs";

export class UserQuery extends Query<IUser> {
  user$: Observable<IUser> = this.select();
  isLoggedIn$: Observable<boolean> = this.select((state) =>
    toBoolean(state.attributes?.email)
  );
  isAdmin$: Observable<boolean> = this.select((state) =>
    toBoolean(
      state.signInUserSession?.idToken.payload["cognito:groups"]?.includes(
        "admin"
      )
    )
  );
  constructor(protected store: UserStore) {
    super(store);
  }
}
