import { useEffect } from "react";

const useScriptParser = (striptData: string, canLoad: boolean) => {
  useEffect(() => {
    if (!canLoad) return;
    let script: HTMLScriptElement = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    try {
      script.appendChild(document.createTextNode(striptData));
      document.body.appendChild(script);
    } catch (e) {
      script.text = striptData;
      document.body.appendChild(script);
    }
  }, [striptData, canLoad]);
};

export default useScriptParser;
