/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:5946e884-6851-4fb6-992e-c2f52dfb0a42",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_faWu1EugX",
    "aws_user_pools_web_client_id": "647dkdjmumkgf9cigavth2u9pc",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "livestreamdb-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "livechanneladminsettingsdb-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "stripecheckoutdb-dev",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "cleartracksapi",
            "endpoint": "https://i8gsyj1ivd.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "adminsettings",
            "endpoint": "https://72p249gv3l.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "resetstreamapi",
            "endpoint": "https://da28tnv462.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "stripecheckoutapi",
            "endpoint": "https://18l1wi2hse.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "publicsettingapi",
            "endpoint": "https://hauii7y9si.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "publicstreamapi",
            "endpoint": "https://ge3hstqbc1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "publicstripecheckout",
            "endpoint": "https://01858e8n87.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
