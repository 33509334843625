import { Grid } from "@material-ui/core";
import Chat from "../../components/chat/Chat";
import React, { useEffect, useState } from "react";
import VideoPlayer from "../../components/video-player/VideoPlayer";
import { useObservable } from "@libreact/use-observable";
import { userFacade } from "../../data-access/user/state/user.facade";
import "./Home.scss";
import { settingFacade } from "../../data-access/settings/settings.facade";
function Home() {
  const [isLoggedIn] = useObservable(userFacade.isLoggedIn$, false);
  const [isVideoExpanded, setVideoExpand] = useState(false);
  const [isDiscord] = useObservable(settingFacade.isDiscord$);
  const [isTipEnabled] = useObservable(settingFacade.isTipEnabled$);

  useEffect(() => {
    if (!isDiscord) {
      setVideoExpand(true);
    } else {
      setVideoExpand(false);
    }
  }, [isDiscord]);
  const expandVideoHandler = () => {
    console.log("video expand toggle", isVideoExpanded);
    const videoExpandFlag = !isVideoExpanded;
    setVideoExpand(videoExpandFlag);
  };
  return (
    <div className="home-page spring">
      <Grid container className="full-height">
        <Grid item xs={isVideoExpanded ? 12 : 8} className="full-height">
          <VideoPlayer
            expandVideoHandler={expandVideoHandler}
            isVideoExpanded={isVideoExpanded}
            isTipEnabled={isTipEnabled}
            isDiscord={!isDiscord}
          />
        </Grid>
        <Grid className={isVideoExpanded ? "hidden" : ""} item xs={4}>
          <Chat />
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
