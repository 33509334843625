import { MenuItem, IconButton, Menu } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { userFacade } from "../../data-access/user/state/user.facade";

function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOutClickHandler = () => {
    userFacade.logoutUser();
    handleMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link to="/settings" className="flex">
          <IconButton
            aria-label="setting"
            aria-controls="primary-search-account-menu"
            color="inherit"
          >
            <SettingsIcon />
          </IconButton>
          <p>Settings</p>
        </Link>
      </MenuItem>

      <MenuItem onClick={logOutClickHandler}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-account-menu"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );
  return (
    <div className="profile-menu">
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
        <ExpandMoreIcon />
      </MenuItem>
      {renderMenu}
    </div>
  );
}

export default ProfileMenu;
