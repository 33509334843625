import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import { AttributeType } from "../../../components/update-user-attribute/UpdateUserAttribute";
import { IUser } from "../../../models/user.interface";
import { UserQuery } from "./user.query";
import { userService, UserService } from "./user.service";
import { userQuery, userStore, UserStore } from "./user.store";

export class UserFacade {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  user$ = this.query.user$;
  isLoggedIn$ = this.query.isLoggedIn$;
  isLoading$ = this.loadingSubject.asObservable();
  isAdmin$ = this.query.isAdmin$;
  constructor(
    private store: UserStore,
    private query: UserQuery,
    private service: UserService
  ) {}

  getUser() {
    const onSuccess = (user: IUser) => {
      this.store.login(user);
      this.loadingSubject.next(false);
    };
    const onError = () => {
      console.error("error getting User");
      this.loadingSubject.next(false);
    };
    this.loadingSubject.next(true);
    this.service.getUser().subscribe(onSuccess, onError);
  }

  logoutUser() {
    const onSuccess = (user: IUser) => {
      this.store.logout();
    };
    const onError = () => {
      console.error("logout error getting User");
    };
    this.service.logout().subscribe(onSuccess, onError);
  }

  updatePassword(oldPassword: string, newPassword: string) {
    return this.service.updatePassord(oldPassword, newPassword);
  }

  updateAttributes(type: AttributeType, value: string) {
    const updatePhoneNumberToStore = () => {
      const storeValue = this.store.getValue();
      const { attributes } = storeValue;
      const updatedAttributes = {
        ...attributes,
        [AttributeType.PHONE_NUMBER]: value,
      };

      const updateUser = {
        ...storeValue,
        attributes: updatedAttributes,
      };
      this.store.updateAttributes(updateUser);
    };

    return this.service.updateAttribute(type, value).pipe(
      tap(() => {
        if (type === AttributeType.PHONE_NUMBER) {
          updatePhoneNumberToStore();
        }
        if (type === AttributeType.EMAIL) {
          this.logoutUser();
        }
      })
    );
  }
}

export const userFacade = new UserFacade(userStore, userQuery, userService);
