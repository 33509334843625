import { Auth } from "aws-amplify";
import { Observable, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AttributeType } from "../../../components/update-user-attribute/UpdateUserAttribute";
import { IUser } from "../../../models/user.interface";

export class UserService {
  getUser(): Observable<IUser> {
    return from(Auth.currentAuthenticatedUser());
  }

  logout() {
    return from(Auth.signOut());
  }

  updatePassord(oldPassword: string, newPassword: string): Observable<string> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap((user: any) => {
        return from(Auth.changePassword(user, oldPassword, newPassword));
      })
    );
  }

  updateAttribute(
    type: AttributeType,
    value: string | number
  ): Observable<string> {
    const attributes = { [type]: value };
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap((user: any) => {
        return from(Auth.updateUserAttributes(user, attributes));
      })
    );
  }
}

export const userService = new UserService();
