import { Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

function Subscribe() {
  return (
    <div className="subscribe">
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Typography variant="h4" style={{ margin: "16px 0" }}>
            Subscribe
          </Typography>
          <Paper variant="outlined" style={{ padding: "16px" }}>
            Subscribe functionality goes here
          </Paper>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
}

export default Subscribe;
