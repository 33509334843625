import { Observable, from } from "rxjs";
import { API } from "aws-amplify";

export interface IPaymentIntentReqData {
  amount: number;
  id: string;
}

export interface IPaymentIntentResponse {
  clientSecret: string;
}
export class PaymentService {
  getPaymentIntent(
    body: IPaymentIntentReqData
  ): Observable<IPaymentIntentResponse> {
    return from(API.post("publicstripecheckout", "/public-stripe", { body }));
  }
}

export const paymentService = new PaymentService();
