import { Store, StoreConfig, transaction } from "@datorama/akita";
import { IUser } from "../../../models/user.interface";
import { UserQuery } from "./user.query";

export function createInitialSessionState(): IUser {
  return {
    challengeName: "",
    challengeParam: { name: "" },
  };
}

@StoreConfig({ name: "session" })
export class UserStore extends Store<IUser> {
  constructor() {
    super(createInitialSessionState());
  }

  login(user: IUser) {
    this._setState(user);
  }
  @transaction()
  updateAttributes(user: IUser) {
    this._setState(user);
  }

  logout() {
    this._setState(createInitialSessionState());
  }
}

export const userStore = new UserStore();
export const userQuery = new UserQuery(userStore);
