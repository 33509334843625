export const VIDEOJS_SCRIPT_ID = "videojs-script-id";
export const GOOGLE_ANALYTICS_SCRIPT_ID = "google-analytics-script-id";
export const VIDEOJS_ANALYTICS_SCRIPT =
  "https://cdn.jsdelivr.net/npm/videojs-analytics@1.0.0/dist/videojs-analytics.min.js";

export const VIDEOJS_IMA_SCRIPT =
  "https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/1.8.3/videojs.ima.min.js";
export const VIDEOJS_IMA_CSS =
  "https://cdnjs.cloudflare.com/ajax/libs/videojs-ima/1.8.3/videojs.ima.css";
export const GOOGLE_IMA_SCRIPT =
  "https://imasdk.googleapis.com/js/sdkloader/ima3.js";

export const AD_CONTRIB_SCRIPT =
  "https://cdn.jsdelivr.net/npm/videojs-contrib-ads@6.7.0/dist/videojs.ads.min.js";

export const getGoogleAnalyticsScript = (id: string) => {
  return `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', '${id}', 'auto');ga('send', 'pageview');`;
};
