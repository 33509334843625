import Button from "@material-ui/core/Button/Button";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Input from "@material-ui/core/Input/Input";
import Typography from "@material-ui/core/Typography/Typography";
import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { ISettingsForm } from "../../models/setting-form.interface";
import "./SettingsForm.scss";
function SettingsForm(props: {
  initalFormValues: ISettingsForm | undefined;
  formSubmitHandler: any;
  resetStreamKeyHandler: any;
  isLoading: boolean;
}) {
  const [values, setValues] = useState(props.initalFormValues);
  const [secretKeyInputType, setSecretKeyInputType] = useState("password");

  useEffect(() => {
    setValues(props.initalFormValues);
  }, [props.initalFormValues]);

  const handleInputChange = (event: any) => {
    const modifyObj: any = {};
    const { name, type } = event.target;
    if (type === "checkbox") {
      modifyObj[name] = event.target.checked;
    } else {
      modifyObj[name] = event.target.value;
    }
    setValues({
      ...values,
      ...modifyObj,
    });
  };

  const updadeInputTypeHandler = () => {
    let inputType: string;
    if (secretKeyInputType === "password") {
      inputType = "text";
    } else {
      inputType = "password";
    }
    setSecretKeyInputType(inputType);
  };

  return (
    <form className="settings-form">
      {/* <div className="form-item flex space-between">
        <Typography variant="body1">One day subscription Price</Typography>
        <Input
          name="oneDaySubsPrice"
          value={values?.oneDaySubsPrice}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Monthly subscription Price</Typography>
        <Input
          name="monthlyDaySubsPrice"
          value={values?.monthlyDaySubsPrice}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Annual subscription Price</Typography>
        <Input
          name="annualDaySubsPrice"
          value={values?.annualDaySubsPrice}
          onChange={handleInputChange}
        />
      </div>
      <hr className="margin-bottom-16 margin-top-16" /> */}

      <div className="form-item flex space-between">
        <Typography variant="body1">Enable Tip/Donations</Typography>
        <div className="checkbox-wrapper flex justify-content-center">
          <Checkbox
            name="isTipEnabled"
            checked={values?.isTipEnabled}
            onChange={handleInputChange}
            color="primary"
            inputProps={{ "aria-label": "tip and donations" }}
          />
        </div>
      </div>
      {/* <div className="form-item flex space-between">
        <Typography variant="body1">Enable subscripition</Typography>
        <div className="checkbox-wrapper flex justify-content-center">
          <Checkbox
            name="isSubscriptionEnabled"
            checked={values?.isSubscriptionEnabled}
            onChange={handleInputChange}
            color="primary"
            inputProps={{ "aria-label": "tip and donations" }}
          />
        </div>
      </div> */}
      <div className="form-item flex space-between">
        <Typography variant="body1">Stripe Secret Key</Typography>
        <Input
          name="stripeSecretKey"
          value={values?.stripeSecretKey}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Enable Discord</Typography>
        <div className="checkbox-wrapper flex justify-content-center">
          <Checkbox
            name="isDiscordEnabled"
            checked={values?.isDiscordEnabled}
            onChange={handleInputChange}
            color="primary"
            inputProps={{ "aria-label": "tip and donations" }}
          />
        </div>
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Discord Key</Typography>
        <Input
          name="discordKey"
          value={values?.discordKey}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Google Analytics</Typography>
        <div className="checkbox-wrapper flex justify-content-center">
          <Checkbox
            name="isGoogleAnalytics"
            checked={values?.isGoogleAnalytics}
            onChange={handleInputChange}
            color="primary"
            inputProps={{ "aria-label": "tip and donations" }}
          />
        </div>
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Google analytics ID</Typography>
        <Input
          name="googleAnalyticsId"
          value={values?.googleAnalyticsId}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Google Ad Manager</Typography>
        <div className="checkbox-wrapper flex justify-content-center">
          <Checkbox
            name="isGoogleAdManager"
            checked={values?.isGoogleAdManager}
            onChange={handleInputChange}
            color="primary"
            inputProps={{ "aria-label": "tip and donations" }}
          />
        </div>
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Ad Tag</Typography>
        <Input
          name="googleAdManagerId"
          value={values?.googleAdManagerId}
          onChange={handleInputChange}
        />
      </div>
      <hr className="margin-bottom-16 margin-top-16" />
      <div className="form-item flex space-between">
        <Typography variant="body1">Stream URL</Typography>
        <Input
          name="stream_url"
          value={values?.stream_url}
          onChange={handleInputChange}
        />
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Stream Key</Typography>
        <div className="secret-key-wrapper flex">
          <Input
            name="stream_secret_key"
            value={values?.stream_secret_key}
            type={secretKeyInputType}
            onChange={handleInputChange}
          />
          <div className="actions">
            <Button
              style={{ margin: "0 8px", width: "74px" }}
              variant="contained"
              onClick={updadeInputTypeHandler}
            >
              {secretKeyInputType === "password" ? "Show" : "Hide"}
            </Button>
            <Button variant="contained" onClick={props.resetStreamKeyHandler}>
              Reset
            </Button>
          </div>
        </div>
      </div>
      <div className="form-footer margin-top-32 flex justify-content-center">
        <Button
          variant="contained"
          color="primary"
          style={{ width: "200px", height: "50px" }}
          onClick={() => props.formSubmitHandler(values)}
        >
          {props.isLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            "Update Changes"
          )}
        </Button>
      </div>
    </form>
  );
}

export default SettingsForm;
