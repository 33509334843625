import { Link, Typography } from "@material-ui/core";
import React from "react";

import "./GeneralRoleSettings.scss";
function GeneralRoleSettings({ isSubscriptionEnabled }: any) {
  return (
    <div className="general-role-settings">
      <div className="form-item flex space-between">
        <Typography variant="body1">Current subscription Price</Typography>
        {isSubscriptionEnabled ? (
          <Link component="button" variant="body2">
            Update payment info
          </Link>
        ) : (
          "-"
        )}
      </div>
      <div className="form-item flex space-between">
        <Typography variant="body1">Bill Date</Typography>
        {isSubscriptionEnabled ? (
          <Link component="button" variant="body2">
            Billing info
          </Link>
        ) : (
          "-"
        )}
      </div>
    </div>
  );
}

export default GeneralRoleSettings;
