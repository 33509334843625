import { Query } from "@datorama/akita";

import { Observable } from "rxjs";
import { ISettingsForm } from "../../models/setting-form.interface";
import { SettingsStore } from "./settings.store";

export class SettingsQuery extends Query<ISettingsForm> {
  settings$: Observable<ISettingsForm> = this.select();
  isDiscord$: Observable<boolean> = this.select(
    (state) => state.isDiscordEnabled
  );
  playbackUrl$: Observable<string> = this.select((state) => state.playbackUrl);
  isTipEnable$: Observable<boolean> = this.select(
    (state) => state.isTipEnabled
  );
  isAnalyticsEnabled$: Observable<boolean> = this.select(
    (state) => state.isGoogleAnalytics
  );
  googleAnalyticsId$: Observable<string> = this.select(
    (state) => state.googleAnalyticsId
  );
  adDetails$: Observable<{
    isGoogleAdManager: boolean;
    googleAdManagerId: string;
  }> = this.select(({ isGoogleAdManager, googleAdManagerId }) => ({
    isGoogleAdManager,
    googleAdManagerId,
  }));

  isSubscriptionEnabled$: Observable<boolean> = this.select(
    (state) => state.isSubscriptionEnabled
  );

  constructor(protected store: SettingsStore) {
    super(store);
  }
}
