import React, { useState } from "react";
import "./VideoPlayer.scss";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useObservable } from "@libreact/use-observable";
import { settingFacade } from "../../data-access/settings/settings.facade";
import IvsPlayerClass from "../ivs-player/IvsPlayerClass";
import StripePayment from "../stripe-payment-modal/StripePaymentModal";

function VideoPlayer(props: any) {
  const [playbackUrl] = useObservable(settingFacade.playbackUrl$);
  const [isAnalyticsEnabled] = useObservable(
    settingFacade.isAnalyticsEnabled$,
    false
  );
  const [adDetails] = useObservable(settingFacade.adDetails$);
  const [showToggleBtn, setShowToggleBtn] = useState(false);
  const onMouseOverHandler = () => {
    if (!props.isDiscord) {
      setShowToggleBtn(true);
    }
  };
  const onMouseOutHandler = () => {
    if (!props.isDiscord) {
      setShowToggleBtn(false);
    }
  };

  const expandToggleHandler = () => {
    if (props.isVideoExpanded) {
      setShowToggleBtn(false);
    }
    props.expandVideoHandler();
  };

  return (
    <div
      className={`video-player flex justify-content-center full-height`}
      onMouseOver={onMouseOverHandler}
      onMouseOut={onMouseOutHandler}
    >
      <div className="video-wrapper flex justify-content-center align-items-center full-width">
        {!!playbackUrl ? (
          <IvsPlayerClass
            playbackUrl={playbackUrl}
            isAnalyticsEnabled={isAnalyticsEnabled}
            isGoogleAdManager={adDetails.isGoogleAdManager}
            googleAdManagerId={adDetails.googleAdManagerId}
          />
        ) : (
          <></>
        )}
      </div>

      <div
        className={`video-expand-toggle flex align-items-center pointer ${
          !showToggleBtn ? "hidden" : ""
        }`}
        onClick={expandToggleHandler}
      >
        {props.isVideoExpanded ? (
          <ArrowBackIosIcon style={{ paddingLeft: "4px" }} color="inherit" />
        ) : (
          <ArrowForwardIosIcon color="inherit" />
        )}
      </div>
      <div className={`tip-btn pointer ${props.isTipEnabled ? "" : "hidden"}`}>
        <StripePayment />
      </div>
    </div>
  );
}

export default VideoPlayer;
