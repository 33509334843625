import {
  AmplifyAuthenticator,
  AmplifySignUp,
} from "@aws-amplify/ui-react/lib-esm/components";
import React from "react";
import { useHistory } from "react-router-dom";
import "./Auth.scss";
import { userFacade } from "../../data-access/user/state/user.facade";
import { Grid } from "@material-ui/core";
function Auth() {
  const history = useHistory();
  const authChangeHandler = (data: any) => {
    console.log("auth data changes", data);
    if (data === "signedin") {
      userFacade.getUser();
      history.push("/");
    }
  };
  return (
    <div className="auth-page margin-top-32">
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <AmplifyAuthenticator
            className="flex justify-content-center"
            usernameAlias="email"
            handleAuthStateChange={authChangeHandler}
          >
            <AmplifySignUp
              slot="sign-up"
              formFields={[
                {
                  type: "username",
                  label: "Email *",
                  required: true,
                  placeholder: "Enter email address",
                },
                {
                  type: "password",
                },
              ]}
            />
          </AmplifyAuthenticator>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
}
export default Auth;
