import { useEffect } from "react";

const useScript = (url: string, canLoad: boolean, id: string = "") => {
  useEffect(() => {
    if (!canLoad) return;
    let script: HTMLScriptElement = document.createElement("script");
    script.id = id;
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
  }, [url, id, canLoad]);
};

export default useScript;
