import React from "react";
import "./Chat.scss";
function Chat() {
  return (
    <div className="chat-page full-height">
      <p className="header flex justify-content-center">Chat Box</p>
    </div>
  );
}

export default Chat;
