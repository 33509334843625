import { useObservable } from "@libreact/use-observable";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { paymentFacade } from "../../../data-access/payment/payment.facade";
import { from } from "rxjs";
import { switchMap } from "rxjs/operators";
import "./StripePaymentForm.scss";
import { snackbarService } from "../../../services/snackbar.service";

export interface IPaymentForm {
  name: string;
  email: string;
  cardNumber: string;
  amount: number;
}

export enum PaymentStatus {
  SUCCESS = "success",
  ERROR = "error",
  IN_PROGRESS = "inProgress",
  NOT_STARTED = "notStarted",
}
const initialValue: IPaymentForm = {
  name: "",
  email: "",
  cardNumber: "",
  amount: 1,
};
function StripePaymentForm(props: any) {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
    PaymentStatus.NOT_STARTED
  );
  const [formValues, setFormValue] = useState(initialValue);
  let [isLoading] = useObservable(paymentFacade.isLoading$);

  useEffect(() => {
    if (paymentStatus === PaymentStatus.SUCCESS) {
      console.log("payment is successful");
      snackbarService.showSnackbar("Payment Successful");
      paymentFacade.updateLoading(false);
      props.handleClose();
    }
    return () => {};
  }, [paymentStatus, props]);
  const inputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const modifyObj: any = {};
    const { name, value } = event.target;
    modifyObj[name] = value;
    setFormValue({ ...formValues, ...modifyObj });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isLoading) return;
    paymentFacade.updateLoading(true);

    let paymentDataResponse: PaymentMethod;
    const createPaymentMethod$ = from(
      stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          name: formValues.name,
          email: formValues.email,
        },
      })
    );

    const payment$ = createPaymentMethod$.pipe(
      switchMap((paymentData) => {
        paymentDataResponse = paymentData.paymentMethod;
        return paymentFacade.getPaymentIntent({
          id: paymentData.paymentMethod.id,
          amount: formValues.amount,
        });
      }),
      switchMap(({ clientSecret }) => {
        return from(
          stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentDataResponse.id,
          })
        );
      })
    );
    payment$.subscribe((data) => {
      setPaymentStatus(PaymentStatus.SUCCESS);
    });
  };

  return (
    <div className="stripe-payment-form">
      <form onSubmit={handleSubmit}>
        <div className="flex column">
          <TextField
            className="strip-form-field-item attribute-field margin-bottom-16"
            required
            label="Name"
            type="text"
            name="name"
            value={formValues.name}
            variant="outlined"
            onChange={inputChangeHandler}
          />
        </div>
        <div className="flex column">
          <TextField
            className="strip-form-field-item attribute-field"
            required
            type="email"
            label="Email"
            name="email"
            value={formValues.email}
            variant="outlined"
            onChange={inputChangeHandler}
          />
        </div>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            className="strip-form-field-item attribute-field"
            value={formValues.amount}
            type="number"
            name="amount"
            onChange={inputChangeHandler}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            labelWidth={60}
          />
        </FormControl>
        <CardElement
          className="strip-form-field-item"
          options={{ hidePostalCode: true }}
        />
        <div className="payment-modal-footer spring flex justify-content-center">
          <Button type="submit" color="primary">
            {isLoading ? "Processing..." : "Pay now"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default StripePaymentForm;
