import React from "react";
import { Route, Redirect } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { userFacade } from "../../data-access/user/state/user.facade";
import { useObservable } from "@libreact/use-observable";
function PrivateRoute({ component: Component, ...rest }: any) {
  const [isLoading] = useObservable(userFacade.isLoading$, true);
  const [isLoggedIn] = useObservable(userFacade.isLoggedIn$, false);
  return !isLoading ? (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/auth", state: { from: props.location } }}
            />
          );
        }

        // logged in so return component
        return <Component {...props} />;
      }}
    />
  ) : (
    <CircularProgress />
  );
}

export default PrivateRoute;
