import { API } from "aws-amplify";
import { Observable, from, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ISettingsForm } from "../../models/setting-form.interface";
import {
  IStreamData,
  IStreamKey,
  IStreamPayloadData,
} from "../stream/stream.service";
import { mapToStreamData } from "./utils/settings.utils";

export const initialValues: ISettingsForm = {
  oneDaySubsPrice: "",
  monthlyDaySubsPrice: "",
  annualDaySubsPrice: "",
  isTipEnabled: false,
  isSubscriptionEnabled: false,
  stripeSecretKey: "",
  isDiscordEnabled: false,
  discordKey: "",
  isGoogleAnalytics: false,
  googleAnalyticsId: "",
  isGoogleAdManager: false,
  googleAdManagerId: "",
  stream_url: "",
  stream_secret_key: "",
  isAdminSettingCreated: false,
  playbackUrl: "",
};
export class SettingsService {
  getUserSettings(userId: string): Observable<ISettingsForm> {
    // return of(initialValues);
    return from(API.get("adminsettings", `/admin-settings`, {})).pipe(
      map((data: any[]) => (data.length ? data[0] : initialValues))
    );
  }

  getPublicSettings(): Observable<ISettingsForm> {
    return from(API.get("publicsettingapi", `/public-settings`, {})).pipe(
      map((data: any[]) => (data.length ? data[0] : initialValues))
    );
  }

  getStreamData(): Observable<IStreamData> {
    return from(API.get("resetstreamapi", "/resetstream", {})).pipe(
      map(mapToStreamData),
      catchError(this.handleError)
    );
  }

  getPublicStreamData(): Observable<IStreamData> {
    return from(API.get("publicstreamapi", "/public-stream", {})).pipe(
      map(mapToStreamData)
    );
  }

  resetStreamData(): Observable<IStreamKey> {
    return from(API.post("resetstreamapi", "/resetstream", {})).pipe(
      map(({ streamKey }: IStreamPayloadData) => streamKey)
    );
  }

  updateSettings(
    { stream_secret_key, stream_url, playbackUrl, ...rest }: ISettingsForm,
    userId: string
  ): Observable<ISettingsForm> {
    // return of({ ...rest, stream_url, stream_secret_key });
    const body = { ...rest, userId };
    return from(API.put("adminsettings", `/admin-settings`, { body })).pipe(
      map((data: any[]) => (data.length ? data[0] : initialValues))
    );
  }

  addSettings(
    { stream_secret_key, stream_url, playbackUrl, ...rest }: ISettingsForm,
    userId: string
  ) {
    console.log("add settings", rest);
    const body = { ...rest, userId };

    return from(API.post("adminsettings", "/admin-settings", { body }));
  }

  private handleError(error: any) {
    console.error(error["error"]);
    return throwError(error["error"] || "Server error");
  }
}

export const settingsService = new SettingsService();
