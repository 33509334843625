import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./Settings.scss";
import SettingsForm from "../../components/settings-form/SettingsForm";
import { ISettingsForm } from "../../models/setting-form.interface";
import { userFacade } from "../../data-access/user/state/user.facade";
import { useObservable } from "@libreact/use-observable";
import GeneralRoleSettings from "../../components/general-role-settings/GeneralRoleSettings";

import { settingFacade } from "../../data-access/settings/settings.facade";
import PersonalSettings from "../../components/personal-settings/PersonalSettings";

function Settings() {
  const [user] = useObservable(userFacade.user$);
  const [isUserAdmin] = useObservable(userFacade.isAdmin$);
  const [settings] = useObservable(settingFacade.settings$);
  const [isLoading, setLoading] = useState(false);

  const submitForm = (data: ISettingsForm) => {
    const userId = user.attributes?.sub || "";
    if (!userId.length) return;
    setLoading(true);
    settingFacade.updateSettings(data, userId);
  };

  const resetStreamKeyHandler = () => {
    settingFacade.resetStreamKey();
  };

  const updatePersonalSetting = (type: string) => {
    console.log("type to update", type);
  };

  useEffect(() => {
    let timeout: any;
    if (isLoading) {
      timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <div className="settings margin-top-32">
      <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <Typography variant="h4" style={{ marginBottom: "16px" }}>
            {isUserAdmin ? "Admin Settings" : "Account Setting"}
          </Typography>
          <div style={{ padding: "16px" }} className={!user ? "hidden" : ""}>
            <PersonalSettings
              email={user.attributes?.email}
              phoneNumber={user.attributes?.phone_number || ""}
              updatePersonalSetting={updatePersonalSetting}
            />
            <hr className="margin-bottom-16 margin-top-16" />
            {isUserAdmin ? (
              <SettingsForm
                initalFormValues={settings}
                formSubmitHandler={submitForm}
                resetStreamKeyHandler={resetStreamKeyHandler}
                isLoading={isLoading}
              />
            ) : (
              <GeneralRoleSettings
                isSubscriptionEnabled={settings.isSubscriptionEnabled}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
}

export default Settings;
