import { Observable, Subject } from "rxjs";
export interface ISnackbarData {
  timeout: number;
  text: string;
}
export class SnackbarService {
  isShowSubject: Subject<boolean> = new Subject();
  snackbarDataSubject: Subject<ISnackbarData> = new Subject();

  isShow$: Observable<boolean> = this.isShowSubject.asObservable();

  snackbarData$: Observable<
    ISnackbarData
  > = this.snackbarDataSubject.asObservable();

  showSnackbar(text: string, timeout: number = 6000) {
    this.snackbarDataSubject.next({ text, timeout });
    this.isShowSubject.next(true);
  }

  hideSnackbar() {
    this.isShowSubject.next(false);
  }
}

export const snackbarService = new SnackbarService();
