import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Navbar from "./components/nav-bar/Navbar";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Auth from "./pages/authentication/Auth";
import Home from "./pages/home/Home";
import Settings from "./pages/settings/Settings";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import Subscribe from "./pages/subscribe/Subscribe";
import { userFacade } from "./data-access/user/state/user.facade";
import { settingFacade } from "./data-access/settings/settings.facade";
import { useObservable } from "@libreact/use-observable";
import UpdatePassword from "./pages/update-password/UpdatePassword";
import UpdateUserAttribute from "./components/update-user-attribute/UpdateUserAttribute";
import SnackbarWrapper from "./components/snackbar-wrapper/SnackbarWrapper";
import useScript from "./hooks/useScript";
import {
  VIDEOJS_ANALYTICS_SCRIPT,
  getGoogleAnalyticsScript,
} from "./constants/scripts";
import useScriptParser from "./hooks/useScriptParser";
Amplify.configure(awsconfig);
function App() {
  const [user] = useObservable(userFacade.user$);
  const [isLoding] = useObservable(userFacade.isLoading$);
  const [isAnalyticsEnabled] = useObservable(
    settingFacade.isAnalyticsEnabled$,
    false
  );
  const [googleAnalyticsId] = useObservable(settingFacade.googleAnalyticsId$);
  useScript(
    VIDEOJS_ANALYTICS_SCRIPT,
    isAnalyticsEnabled && !!googleAnalyticsId.length
  );
  useScriptParser(
    getGoogleAnalyticsScript(googleAnalyticsId),
    isAnalyticsEnabled && !!googleAnalyticsId.length
  );
  useEffect(() => {
    userFacade.getUser();
  }, []);
  useEffect(() => {
    if (isLoding) return;
    const userId = user.attributes?.sub || "";
    if (userId.length) {
      settingFacade.getAllData(userId);
    } else {
      settingFacade.getPublicData();
    }
  }, [user, isLoding]);
  return (
    <div className="app flex column">
      <Navbar />
      <Switch>
        <Route path="/" component={Home} exact />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/subscribe" component={Subscribe} />
        <PrivateRoute path="/update-password" component={UpdatePassword} />
        <Route path="/auth" component={Auth} />
        <PrivateRoute
          path="/update/:attribute"
          component={UpdateUserAttribute}
        />
        <Redirect from="*" to="/" />
      </Switch>
      <SnackbarWrapper />
    </div>
  );
}

export default App;
