const ALL_ADS_COMPLETED = "allAdsCompleted",
  CLICK = "click",
  COMPLETE = "complete",
  FIRST_QUARTILE = "firstQuartile",
  LOADED = "loaded",
  MIDPOINT = "midpoint",
  PAUSED = "pause",
  RESUMED = "resume",
  STARTED = "start",
  SKIPPED = "skip",
  THIRD_QUARTILE = "thirdQuartile";
export const VIDEOJS_IMA_EVENTS = [
  ALL_ADS_COMPLETED,
  CLICK,
  COMPLETE,
  FIRST_QUARTILE,
  LOADED,
  MIDPOINT,
  PAUSED,
  RESUMED,
  STARTED,
  SKIPPED,
  THIRD_QUARTILE,
];
