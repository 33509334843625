import { Subject } from "rxjs";
import { startWith } from "rxjs/operators";
import { IPaymentIntentReqData, paymentService } from "./payment.service";
export class PaymentFacade {
  private loadingSubject: Subject<boolean> = new Subject();
  isLoading$ = this.loadingSubject.asObservable().pipe(startWith(false));

  getPaymentIntent({ amount, id }: IPaymentIntentReqData) {
    return paymentService.getPaymentIntent({ amount: amount * 100, id });
  }

  updateLoading(isLoading: boolean) {
    this.loadingSubject.next(isLoading);
  }
}

export const paymentFacade = new PaymentFacade();
