import { Link, Typography } from "@material-ui/core";
import React from "react";
import { Link as RouteLink } from "react-router-dom";
import { AttributeType } from "../update-user-attribute/UpdateUserAttribute";

export interface IPersnalSettingsProps {
  email: string;
  phoneNumber: string;
  updatePersonalSetting: Function;
}
function PersonalSettings(props: IPersnalSettingsProps) {
  return (
    <div className="personal-settings">
      <div className="setting-item flex space-between">
        <Typography variant="body1">{props.email}</Typography>
        <RouteLink to={`/update/${AttributeType.EMAIL}`}>
          <Link component="button" variant="body2">
            Update Email
          </Link>
        </RouteLink>
      </div>
      <div className="setting-item flex space-between">
        <Typography variant="body1"></Typography>
        <RouteLink to="/update-password">
          <Link component="button" variant="body2">
            Change Password
          </Link>
        </RouteLink>
      </div>
      <div className="setting-item flex space-between">
        <Typography variant="body1">{props.phoneNumber}</Typography>
        <RouteLink to={`/update/${AttributeType.PHONE_NUMBER}`}>
          <Link component="button" variant="body2">
            {props.phoneNumber.length ? "Change" : "Add"} Phone Number
          </Link>
        </RouteLink>
      </div>
    </div>
  );
}

export default PersonalSettings;
