import { Store, StoreConfig } from "@datorama/akita";
import { ISettingsForm } from "../../models/setting-form.interface";
import { SettingsQuery } from "./settings.query";
import { initialValues } from "./settings.service";

export function createInitialSessionState(): ISettingsForm {
  return {
    ...initialValues,
  };
}

@StoreConfig({ name: "settings" })
export class SettingsStore extends Store<ISettingsForm> {
  constructor() {
    super(createInitialSessionState());
  }

  add(settings: ISettingsForm) {
    this._setState(settings);
  }

  reset() {
    this._setState(createInitialSessionState());
  }

  updateValue(stream_secret_key: string) {
    this.update((state) => ({ ...state, stream_secret_key }));
  }
}

export const settingsStore = new SettingsStore();
export const settingsQuery = new SettingsQuery(settingsStore);
