import React, { useState } from "react";
import "./UpdatePassword.scss";
import { Typography, TextField, Button, Link } from "@material-ui/core";
import { Link as RouteLink } from "react-router-dom";
import { userFacade } from "../../data-access/user/state/user.facade";
function UpdatePassword() {
  const initialValue = {
    oldPassword: "",
    newPassword: "",
  };

  const [formValues, setFormValue] = useState(initialValue);
  const [isPasswordUdpated, setPasswordUpdate] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);

  const inputChangeHandler = (event: any) => {
    console.log("Event", event.target);
    if (isPasswordError) {
      setPasswordError(false);
    }
    const modifyObj: any = {};
    const { name, value } = event.target;
    modifyObj[name] = value;
    setFormValue({ ...formValues, ...modifyObj });
  };

  const onUpdatePassword = () => {
    console.log("updated passowrd");
    const onSuccess = (status: string) => {
      console.log("Passowrd updated", status);
      setPasswordUpdate(true);
    };
    const onError = () => {
      console.error("error updating password");
      setPasswordError(true);
    };
    userFacade
      .updatePassword(formValues.oldPassword, formValues.newPassword)
      .subscribe(onSuccess, onError);
    // userFacade.updateAttributes();
  };

  return (
    <div className="update-password flex justify-content-center margin-top-32">
      <div className="update-password-content">
        {isPasswordUdpated ? (
          <div className="password-success">
            <Typography variant="h5">Password updated successfully!</Typography>
            <RouteLink to="/settings">
              <Link
                style={{ marginTop: "16px" }}
                component="button"
                variant="body2"
              >
                Go back to setting page
              </Link>
            </RouteLink>
          </div>
        ) : (
          <div className="form-wrapper">
            <Typography className="password-header" variant="h6">
              Change Password
            </Typography>
            <div className=" flex column">
              <TextField
                className="old-password"
                required
                id="outlined-required"
                label="Old password"
                name="oldPassword"
                value={formValues.oldPassword}
                onChange={inputChangeHandler}
                variant="outlined"
              />
              <TextField
                required
                className="margin-top-16"
                id="outlined-required"
                name="newPassword"
                label="New password"
                value={formValues.newPassword}
                onChange={inputChangeHandler}
                variant="outlined"
              />
            </div>
            <div className="button-wrapper flex column justify-content-center">
              <Button
                className="password-submit-btn"
                variant="contained"
                color="primary"
                onClick={onUpdatePassword}
                style={{
                  backgroundColor: "#ff9900",
                  width: "150px",
                  padding: "10px 8px",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                Update
              </Button>
              {isPasswordError ? (
                <Typography variant="body2" color="error">
                  Error updating the password, please verify old password and
                  try again!
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdatePassword;
