import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useObservable } from "@libreact/use-observable";
import { snackbarService } from "../../services/snackbar.service";

function SnackbarWrapper() {
  const [open] = useObservable(snackbarService.isShow$);
  const [snackbarData] = useObservable(snackbarService.snackbarData$);
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    console.log("handleClose in auto hide");
    snackbarService.hideSnackbar();
  };
  return (
    <div className={`snackbar-wrapper`}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={snackbarData?.timeout}
        onClose={handleClose}
        message={snackbarData?.text}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default SnackbarWrapper;
