import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Button, Typography } from "@material-ui/core";
import ProfileMenu from "../profile-menu/ProfileMenu";
import { userFacade } from "../../data-access/user/state/user.facade";
import { useObservable } from "@libreact/use-observable";
import Logo from "../../assets/images/logo.jpg";
import "./Navbar.scss";
import { settingFacade } from "../../data-access/settings/settings.facade";
function Navbar() {
  const [isLoggedIn] = useObservable(userFacade.isLoggedIn$, false);
  const [isSubscriptionEnabled] = useObservable(
    settingFacade.isSubscriptionEnabled$
  );
  return (
    <div className="navbar">
      <AppBar position="static" style={{ display: "flex" }} color="transparent">
        <Toolbar>
          <img alt="site logo" className="logo" src={Logo} />
          <Typography variant="h6">
            <Link to="/">
              <Button color="default">Crossfader</Button>
            </Link>
          </Typography>
          <div
            className="flex align-items-center"
            style={{ marginLeft: "auto" }}
          >
            <Link
              to="/subscribe"
              className={!isSubscriptionEnabled ? "hidden" : ""}
            >
              <Button color="inherit">Subscribe</Button>
            </Link>
            {isLoggedIn ? (
              <ProfileMenu />
            ) : (
              <Link to="/auth">
                <Button color="inherit">Login</Button>
              </Link>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navbar;
