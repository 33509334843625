import { IStreamData, IStreamPayloadData } from "../../stream/stream.service";

export const mapToStreamData = ({
  channel,
  streamKey,
}: IStreamPayloadData): IStreamData => ({
  stream_secret_key: streamKey?.value,
  stream_url: createServerUrl(channel.ingestEndpoint),
  playbackUrl: channel.playbackUrl,
});

export const createServerUrl = (url: string): string => {
  return `rtmps://${url}:443/app/`;
};
