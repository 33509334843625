import React, { Component } from "react";
import { VIDEOJS_IMA_EVENTS } from "./ivs-player.constant";
declare var window: any;
declare var google: any;
export class IvsPlayerClass extends Component<{
  playbackUrl: string;
  isAnalyticsEnabled: boolean;
  isGoogleAdManager: boolean;
  googleAdManagerId: string;
}> {
  private videoJSPlayer: any;
  private ivsPlayer: any;
  playingEvent = () => {
    console.log("Player State - PLAYING");

    console.log(
      `This stream is ${
        this.ivsPlayer.isLiveLowLatency() ? "" : "not "
      }playing in ultra low latency mode`
    );
    console.log(`Stream Latency: ${this.ivsPlayer.getLiveLatency()}s`);
  };

  metadataCue = (cue: any) => {
    const metadataText = cue.text;
    const position = this.ivsPlayer.getPosition().toFixed(2);
    console.log(
      `Player Event - TEXT_METADATA_CUE: "${metadataText}". Observed ${position}s after playback started.`
    );
  };

  onAdCompletedEvent = (event: any) => {
    if (
      event.type === google.ima.AdEvent.Type.COMPLETE ||
      google.ima.AdEvent.Type.SKIPPED
    ) {
      this.ivsPlayer.play();
    }
  };

  adsManagerLoadedCallback = () => {
    var events = VIDEOJS_IMA_EVENTS;
    for (var index = 0; index < events.length; index++) {
      this.videoJSPlayer.ima.addEventListener(
        events[index],
        this.onAdCompletedEvent.bind(this)
      );
    }
  };

  errorEvent = (type: any, source: any) => {
    console.warn("Player Event - ERROR: ", type, source);
  };
  componentDidMount() {
    window.registerIVSTech(window.videojs);
    window.registerIVSQualityPlugin(window.videojs);

    this.videoJSPlayer = window.videojs("amazon-ivs-videojs", {
      techOrder: ["AmazonIVS"],
      controlBar: {
        playToggle: {
          replay: false,
        }, // Hides the replay button for VOD
        pictureInPictureToggle: false, // Hides the PiP button
      },
    });
    if (this.props.isAnalyticsEnabled) {
      // this.videoJSPlayer.analytics();
    }
    this.ivsPlayer = this.videoJSPlayer.getIVSPlayer();
    const PlayerState = this.videoJSPlayer.getIVSEvents().PlayerState;

    this.ivsPlayer.addEventListener(PlayerState.PLAYING, this.playingEvent);

    // Log errors
    const PlayerEventType = this.videoJSPlayer.getIVSEvents().PlayerEventType;
    this.ivsPlayer.addEventListener(PlayerEventType.ERROR, this.errorEvent);

    // Log and display timed metadata
    this.ivsPlayer.addEventListener(
      PlayerEventType.TEXT_METADATA_CUE,
      this.metadataCue
    );

    // Enables manual quality selection plugin
    this.videoJSPlayer.enableIVSQualityPlugin();

    // Set volume and play default stream
    this.videoJSPlayer.volume(0.5);

    this.videoJSPlayer.src(this.props.playbackUrl);
  }

  componentDidUpdate() {
    if (
      this.props.isGoogleAdManager &&
      this.props.googleAdManagerId &&
      typeof this.videoJSPlayer?.ads === "function"
    ) {
      console.log("am i inside");
      var options = {
        adTagUrl: this.props.googleAdManagerId,
        adsManagerLoadedCallback: this.adsManagerLoadedCallback,
      };
      this.videoJSPlayer.ima(options);
    }
  }

  shouldComponentUpdate(prevState: any) {
    return (
      !this.props.isGoogleAdManager &&
      !this.props.googleAdManagerId &&
      prevState.isGoogleAdManager
    );
  }
  componentWillUnmount() {
    const PlayerState = this.videoJSPlayer.getIVSEvents().PlayerState;
    const PlayerEventType = this.videoJSPlayer.getIVSEvents().PlayerEventType;
    this.ivsPlayer.removeEventListener(PlayerState.PLAYING, this.playingEvent);
    this.ivsPlayer.removeEventListener(
      PlayerEventType.TEXT_METADATA_CUE,
      this.metadataCue
    );
    this.ivsPlayer.removeEventListener(PlayerEventType.ERROR, this.errorEvent);
    this.videoJSPlayer.dispose();
  }
  render() {
    return (
      <div data-vjs-player>
        <video
          className="video-js vjs-fill vjs-big-play-centered"
          id="amazon-ivs-videojs"
          playsInline
          controls
          autoPlay
        ></video>
      </div>
    );
  }
}

export default IvsPlayerClass;
