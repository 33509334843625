import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import React, { useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import { userFacade } from "../../data-access/user/state/user.facade";
import "./UpdateUserAttribute.scss";

export enum AttributeType {
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
}

export const AttributeTypeLabel = {
  [AttributeType.EMAIL]: "Email",
  [AttributeType.PHONE_NUMBER]: "Phone Number",
};
function UpdateUserAttribute(props: any) {
  const {
    match: { params },
  } = props;
  const type: AttributeType = params.attribute;
  const initialValue = {
    [type]: "",
  };
  const [formValues, setFormValue] = useState(initialValue);
  const [isAttributeUdpated, setAttributeUpdate] = useState(false);
  const [isAttributeError, setAttributeError] = useState(false);

  const inputChangeHandler = (event: any) => {
    if (isAttributeError) {
      setAttributeError(false);
    }
    const modifyObj: any = {};
    const { name, value } = event.target;
    modifyObj[name] = value;
    console.log("Event", modifyObj);
    setFormValue({ ...modifyObj });
  };

  const onUpdateAttribute = () => {
    // console.log("updated passowrd");
    const onSuccess = (status: string) => {
      console.log("Passowrd updated", status);
      setAttributeUpdate(true);
    };
    const onError = () => {
      console.error("error updating Attribute");
      setAttributeError(true);
    };
    console.log(type, formValues[type]);
    userFacade
      .updateAttributes(type, formValues[type])
      .subscribe(onSuccess, onError);
  };

  return (
    <div className="update-user-attribute flex justify-content-center margin-top-32">
      <div className="update-attribute-content">
        {isAttributeUdpated ? (
          <div className="attribute-success">
            <Typography variant="h5">
              {AttributeTypeLabel[type]} updated successfully!
            </Typography>
            <RouteLink to="/settings">
              <Link
                style={{ marginTop: "16px" }}
                component="button"
                variant="body2"
              >
                Go back to setting page
              </Link>
            </RouteLink>
          </div>
        ) : (
          <div className="form-wrapper">
            <Typography className="attribute-header" variant="h6">
              Change {AttributeTypeLabel[type]}
            </Typography>
            <div className=" flex column">
              <TextField
                className="attribute-field"
                required
                id="outlined-required"
                label={AttributeTypeLabel[type]}
                name={type}
                value={formValues[type]}
                onChange={inputChangeHandler}
                variant="outlined"
              />
            </div>
            <div className="button-wrapper flex column justify-content-center">
              <Button
                className="attribute-submit-btn"
                variant="contained"
                color="primary"
                onClick={onUpdateAttribute}
                style={{
                  backgroundColor: "#ff9900",
                  width: "150px",
                  padding: "10px 8px",
                  marginTop: "16px",
                  marginBottom: "16px",
                }}
              >
                Update
              </Button>
              {isAttributeError ? (
                <Typography variant="body2" color="error">
                  Error updating the {AttributeTypeLabel[type].toLowerCase()},
                  please verify input and try again!
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdateUserAttribute;
